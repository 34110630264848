import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero/hero"
import RightCta from "../../components/cta/right-bordered/cta"
import LeftCta from "../../components/cta/left-bordered/cta"
import Savings from "../../components/savings/savings";

import desktopHero from "../../images/dosing/desktopHero.png"
import mobileHero from "../../images/dosing/mobileHero.png"
import gradientOne from "../../images/dosing/gradientOne.png"
import gradientTwo from "../../images/dosing/gradientTwo.svg"
import gradientThree from "../../images/dosing/gradientThree.png"
import ctaBackground from "../../images/dosing/ctaBackground.svg"


import twoPillsIcon from "../../images/dosing/4.1_2_Pills_Icon-01.png"
import fourPillsIcon from "../../images/dosing/fourPillsIcon.png"
import glassHeart from "../../images/benefits/crystal-heart.png"
import crystalHeartMobile from "../../images/dosing/crystalHeartMobile.png"
import brochureIcon from "../../images/dosing/brochure-icon.png"


export default () => (
  <Layout>
    <SEO
      title="Learn About Dosing Guidelines"
      keywords="VASCEPA dosing, VASCEPA dosage, VASCEPA dose"
      description="VASCEPA® (icosapent ethyl) is a FDA-approved prescription to be taken with your statin. Learn more about how to take VASCEPA. Please see Indication and Important Safety Information."
      />


      <Hero
        video={true}
        desktopIframe={[<iframe style={{height:"1000px"}} className="desktopHeroBackground" title="Background Video" src="https://player.vimeo.com/video/400606241?background=1" width="1440" height="1000" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>]}
        mobileIframe={[<iframe className="mobileHeroBackgroundVideo" title="Background Video" src="https://player.vimeo.com/video/400606266?background=1" width="100%" height="355" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>]}
        desktopHeroBackground={desktopHero}
        mobileHeroBackground={mobileHero}
        headline={["How to take",<br/>,"FDA-approved prescription VASCEPA"]}
      />

      <div className="block row">

        <div className="col-md-7 block-content whiteborderRight" >
          <img class="background-gradient" src={gradientOne} alt=""></img>
          <div>
            <h3 className="font-margins"
              style={{fontSize: "30px", lineHeight:"42px", fontWeight:"700",zIndex:"1",position:"relative",marginTop:"10%",marginBottom:"auto", marginBottom: "30px"}}
              >VASCEPA is FDA-approved to be taken with your statin. If you cannot take statins, talk to your doctor about whether or not VASCEPA can help you.
            </h3>

        </div>

        </div>

        <div style={{padding:"0px", minHeight:"300px"}} className="col-md-5 block-content ">
          <img class="background-gradient getting-started-dosing-image-fix" src={gradientTwo} alt="" ></img>
          <div>
          <h3 className="font-margins"
            style={{fontSize: "30px", lineHeight:"42px", fontWeight:"700",zIndex:"1",position:"relative",color:"#1D355E",marginTop:"5%",marginBottom:"auto"}}
            >The right dose makes a difference 
          </h3>
            <p className="font-margins"
              style={{fontSize: "18px", lineHeight:"22px", fontWeight:"500",zIndex:"1",position:"relative",color:"#1D355E",marginTop:"5%",marginBottom:"auto", marginBottom: "30px"}}
              >In clinical testing conducted during the REDUCE-IT<sup>®</sup> trial, patients took the FDA-approved 4-gram dose (four 1-gram capsules, or eight <span className="no-wrap">0.5-gram</span> capsules) each day to achieve 25% added heart protection on top of statins.  Patients treated with VASCEPA had fewer cardiovascular events (17.2%) compared to those who took placebo (22%).</p>
            </div>
        </div>

      </div>

      <div className="block row">
        <div  style={{textAlign:"center"}} className="col-md-12 block-content">
          <h4 className="mobile-p-x-5percent mobile-p-t-5"
            style={{fontSize: "25px", lineHeight:"34px", fontWeight:"700",zIndex:"1",position:"relative",color:"#1D355E",marginTop:"2%",marginBottom:"auto", marginRight: "auto", marginLeft: "auto"}}
            >
            To ensure you get the full benefit of VASCEPA, it’s important to follow these dosing guidelines:
          </h4>

         </div>
        <div className="col-md-6 block-content" >
          <div style={{    marginLeft: "15%", marginRight: "15%", marginTop: "10%"}}>
            <img style={{maxWidth:"100%"}} src={twoPillsIcon} alt=""></img>
            <h6 className=""
              style={{fontSize: "18px", lineHeight:"22px", fontWeight:"300",zIndex:"1",position:"relative",marginTop:"10%",color:"#191919"}}>For the standard size, take two 1-gram capsules twice a day with food.
              </h6>
          </div>

        </div>


        <div style={{padding:"0px", minHeight:"245px"}} className="col-md-6 block-content block-column-border-left block-column-mobile-border-top  ">          <div style={{    marginLeft: "15%", marginRight: "15%", marginTop: "10%"}}>
          <img style={{maxWidth:"100%"}} src={fourPillsIcon} alt=""></img>

          <h6 className=""
            style={{fontSize: "18px", lineHeight:"22px", marginTop: "10%", fontWeight:"300",zIndex:"1",position:"relative",color:"#191919"}}>If you prefer the smaller size, take four of the <span className="no-wrap">0.5-gram</span> capsules twice a day with food.
            </h6>
            </div>
        </div>
        <div  style={{textAlign:"center"}} className="col-md-12 block-content">
          <h6 className="font-margins"
            style={{fontSize: "18px", lineHeight:"22px", fontWeight:"300",zIndex:"1",position:"relative",marginTop:"0%",marginBottom:"5%",color:"#191919"}}>Swallow capsules whole. Do not break open, crush, dissolve, or chew.
            </h6>

         </div>

      </div>

      {<RightCta
        colOne="5"
        colTwo="7"
        alt="Vascepa Glass heart"
        whiteborder="whiteborder"
        image={glassHeart}
        mobileImage={crystalHeartMobile}
        background={gradientOne}
        headline="Ready to talk to your doctor about VASCEPA? Our doctor discussion guide can help start the conversation."
        cta={["Download the guide ", <span aria-hidden="true">&gt;</span>]}
        external="true"
        url="https://amarincorp.com/docs/Vascepa-Downloadable-DDG-2020.pdf"
        mobileImageClass="img-mobile"
        imgSM="mobile-height-150"
        />
      }
        
        <LeftCta
        alt="Brochure icon"
        ctaBackground = {ctaBackground}
        colOne="5"
        whiteborder="transparentborderRight"
        colTwo="7"
        image={brochureIcon}
        mobileImage={brochureIcon}
        headline="Learn more about cardiovascular risk and VASCEPA."
        cta={["Get the brochure ", <span aria-hidden="true">&gt;</span>]}
        headlineColor = "#1D355E"
        ctaColor = "#1D355E"
        contentSubClass="left"
        ctaSubClass="left"
        url="https://amarincorp.com/docs/VAS-02344VascepaHCCPatientBrochure_CVLaunch_R1.pdf"
        external="true"

        /> 



  </Layout>
)
